import React, { useState } from "react";
import "../Navbar/navbar.css";
import { Link } from 'react-router-dom';
import { BsRobot } from "react-icons/bs";
import CIcon from '@coreui/icons-react';
import { cibSap } from '@coreui/icons';
import {cibOracle} from '@coreui/icons'
import { GiRobotGolem } from "react-icons/gi";
import { TbDeviceImacUp } from "react-icons/tb";
import { FaBattleNet } from "react-icons/fa";
import { IoMdCloudUpload } from "react-icons/io";
import { GrInternetExplorer } from "react-icons/gr";
import { GiPlatform } from "react-icons/gi";
import { SiBmcsoftware } from "react-icons/si";
import { FaSalesforce } from "react-icons/fa6";
import { GrTechnology } from "react-icons/gr";
import { FaCodeMerge } from "react-icons/fa6";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { SiPaloaltosoftware } from "react-icons/si";
import { FaGopuram } from "react-icons/fa6";
import { SiVala } from "react-icons/si";
import { FaWalkieTalkie } from "react-icons/fa6";
import { BsMicrosoftTeams } from "react-icons/bs";
import { FaDirections } from "react-icons/fa";
import { MdManageHistory } from "react-icons/md";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img className="logo-img" src="logon.jpeg" alt="Logo" />
        </Link>
      </div>
      <div className="navbar-toggle" onClick={toggleNavbar}>
        <div className="toggle-bar"></div>
        <div className="toggle-bar"></div>
        <div className="toggle-bar"></div>
      </div>
      <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <ul className="navbar-ul ">
          <li>
            <a className="link-text" href="/">
              Home
            </a>
          </li>

          <li className="dropdown">
            <a href="/aboutus" className="dropbtn no-underline">
              About Us
            </a>
            <div className="dropdown-content-2">
              <div className="dropdown-all-1">
                <a className="dropdown-text" href="/aboutus#purpose">
                  <FaGopuram className="nav-icon" />
                  Purpose
                </a>
                <a className="dropdown-text" href="/aboutus#value">
                  <SiVala className="nav-icon" />
                  Value
                </a>
                <a className="dropdown-text" href="/aboutus#team">
                  <BsMicrosoftTeams className="nav-icon" />
                  Team
                </a>
                <a className="dropdown-text" href="/aboutus#why">
                  <FaDirections className="nav-icon" />
                  Why Direccion
                </a>
              </div>
            </div>
          </li>
          <li className="dropdown">
            <a href="#" className="dropbtn no-underline">
              Services
            </a>
            <div className="dropdown-content">
              <div className="dropdown-all">
                <div className="dropdown-one">
                  <a
                    className="dropdown-text-main"
                    href="/artificialintelligence"
                  >
                     ARTIFICIAL INTELLIGENCE
                  </a>

                  <a className="dropdown-subtext" href="/artificialintelligence#ml">
                    <TbDeviceImacUp className="nav-icon" /> Machine Learning
                  </a>
                  {/*-
                  <a className="dropdown-subtext" href="/artificialintelligence#aibots">
                    <FaBattleNet className="nav-icon" /> AI Bots
                  </a>
                  -*/}
                </div>
                <div className="dropdown-one">
                  <a className="dropdown-text-main" href="/cloudservices">
                     CLOUD SERVICES
                  </a>
                  <a className="dropdown-subtext" href="/cloudservices#sapsupport">
                  <CIcon className="nav-icon2" icon={cibSap} />
                    SAP Support
                  </a>
                  <a className="dropdown-subtext" href="/cloudservices#wds">
                    <GiPlatform className="nav-icon" />
                    Workday Support
                  </a>
                  <a className="dropdown-subtext" href="/cloudservices#os">
                  <CIcon className="nav-icon2" icon={cibOracle} />
                    Oracle Support
                  </a>
                  <a className="dropdown-subtext" href="/cloudservices#sfs">
                    <FaSalesforce className="nav-icon" />
                    Salesforce Support
                  </a>
                </div>
                <div className="dropdown-one">
                  <a
                    className="dropdown-text-main"
                    href="/technologyconsulting"
                  >
                    TECHNOLOGY CONSULTING
                  </a>
                  <a className="dropdown-subtext" href="/technologyconsulting#dt">
                    <FaCodeMerge className="nav-icon" />
                    Digital Transformation
                  </a>
                  <a className="dropdown-subtext" href="/technologyconsulting#cs">
                    <CgArrowsExchangeAlt className="nav-icon" />
                    Cyber Security
                  </a>
                  <a className="dropdown-subtext" href="/technologyconsulting#as">
                    <FaWalkieTalkie className="nav-icon" />
                    Advisory Services
                  </a>
                  <a className="dropdown-subtext" href="/technologyconsulting#pci">
                    <SiPaloaltosoftware className="nav-icon" />
                    Process Consulting & Implementation
                  </a>
                  <a className="dropdown-subtext" href="/technologyconsulting#mso">
                    <MdManageHistory className="nav-icon" />
                    Managed Services & Outsourcing
                  </a>
                  <a className="dropdown-subtext" href="/technologyconsulting#tce">
                    <GiRobotGolem className="nav-icon" />
                    Training & Center of Excellence
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li>
            <a className="link-text" href="/deliverymodel">
              Delivery Model
            </a>
          </li>
          <li>
            <a className="link-text" href="/contactus">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
