import React, { useState } from 'react';
import '../Chatbox/Chat.css'
import { IoMdChatboxes } from "react-icons/io";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');

  // Function to handle sending messages
  const sendMessage = () => {
    // Implement sending message functionality here
    // Update messages state with the new message
  };

  const calladmin = () => {
    window.location.href = 'tel:+917303748900'; // Replace with the actual phone number
  };

  const openWhatsApp = () => {
    const whatsappUrl = 'https://api.whatsapp.com/send/?phone=%2B917303748900&text&type=phone_number&app_absent=0';
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="chat-component">
        <div className="messages">
          {/* Render messages here */}
        </div>
        <IoMdChatboxes className='icon-chat' onClick={openWhatsApp}/>
      </div>
      <div className="chat-component2">
        <div className="messages">
          {/* Render messages here */}
        </div>
        <MdOutlineWifiCalling3 className='icon-chat' onClick={calladmin}/>
      </div>
     
    </>
  );
};

export default Chat;
